body {
    margin: 0px;
    background-color: rgb(233, 233, 233);
    color: #575757;
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    height: min-content;
    align-self: stretch;
    text-align: center;
    align-items: center;
    flex-direction: column;

}

.cTAs.cTAs {
    width: 100%;
    align-self: stretch;
}

.cTAs2.cTAs2 {
    width: 100%;
    align-self: stretch;
}

.question {
    width: 24px;
    height: 24px;
}

.root6 {
    width: min-content;
    height: 44px;
    place-content: center;
    align-items: center;
    gap: 8px;
    padding: 14px 32px;
    outline: solid 1px #ababab;
    outline-offset: -1px;
    border-radius: 40px;
    background-color: #fff;
    overflow: hidden;
}
.root6:disabled {
    background-color: #ababab;
}

.root5 {
    width: min-content;
    height: 44px;
    place-content: center;
    align-items: center;
    gap: 8px;
    padding: 14px 32px;
    border-radius: 40px;
    background-color: #ffb100;
    overflow: hidden;
}

.root5:disabled {
    background-color: #ababab;
}

.vector3 {
    position: absolute;
    left: 45.3125%;
    right: 45.3125%;
    top: 65.625%;
    bottom: 25%;
    overflow: visible;
}

.vector4 {
    position: absolute;
    left: 39.0624%;
    right: 39.0626%;
    top: 31.2517%;
    bottom: 43.7483%;
    overflow: visible;
}

.icon {
    width: 100%;
    height: 100%;
}

.icon2 {
    width: 100%;
    height: 100%;
}

.icon3 {
    width: 100%;
    height: 100%;
}

.vector2 {
    position: absolute;
    left: 12.5%;
    right: 12.5%;
    top: 12.5%;
    bottom: 12.5%;
    overflow: visible;
}

.label2 {
    height: min-content;
    flex: 1;
}

.trailingIcon {
    width: min-content;
    height: min-content;
}

.root4 {
    position: relative;
    width: 50px;
    height: 50px;
    align-items: flex-start;
}

.inputField {

    width: initial;
    height: 76px;
    align-self: stretch;

}

.inputFieldDefault {
    height: 50px;
    align-self: stretch;
    align-items: center;
    padding: 12px;
    outline: solid 1px #d8d8d8;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
}

.inputFieldDefault .inputFieldText {
    height: 100%;
    width: 100%; /* Adjust the width by subtracting the total gap (24px on each side) */
    text-align: start;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    padding: 0;
    margin: 0;
    padding-left: 20px; /* Add a left margin to create a gap on the left */
}

.textHint {
    height: min-content;
    align-self: stretch;
    overflow: hidden;
}

.root3 {
    width: 100%;
    height: min-content;
    flex-direction: column;
    gap: 8px;
}

.fields {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    gap: 40px;
    padding: 10px 56px;
    padding-bottom: 60px;
    border-radius: 12px;
    background-color: #fff;
    overflow: hidden;
}

.intro {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    gap: 24px;
}

.getStarted {
    color: #262626;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    font-family: DM Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
}

.loremIpsumDolorSitAmetConsecte {
    color: #575757;
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    height: min-content;
    align-self: stretch;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.fields2 {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    gap: 32px;
}

.label {
    color: #262626;
    font-size: 15px;
    font-weight: 500;
    font-family: DM Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    height: min-content;
    flex: 1;
    text-align: justify;
    filter: blur(0px);
    flex-direction: column;
    width:100%;
}

.confirmButton {
    width: min-content;
    height: 44px;
    place-content: center;
    align-items: center;
    gap: 8px;
    padding: 14px 32px;
    border-radius: 40px;
    background-color: #ffb100;
    overflow: hidden;

}

.resetButton {
    width: min-content;
    height: 44px;
    place-content: center;
    align-items: center;
    gap: 8px;
    padding: 14px 32px;
    outline: solid 1px #ababab;
    outline-offset: -1px;
    border-radius: 40px;
    background-color: #fff;
    overflow: hidden;
}

button:hover {
    outline: solid 2px #595757;;
}
button:disabled:hover {
    outline: solid 2px #ababab;
}

.root1 {
    position: relative;
    width: 100%;
    height: 1024px;
    align-items: flex-start;
    background-color: #f6f6f6;
    overflow: hidden;
}

.nav {
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: min-content;
    flex-direction: column;
    align-items: center;
    padding: 20px 591px;
    background-color: #fff;
    box-shadow: inset 0px -1px 0px #d8d8d8;
    transform: translateX(-50%);
}

.dBLogo {
    width: 100%;
    height: 33px;
    justify-content: center;
    overflow: visible;
    display: flex;
}

.module {
    position: absolute;
    left: 10%;
    top: 100px;
    width: 80%;
    height: min-content;
    flex-direction: column;
    gap: 40px;
    margin: auto;
}
.module2 {
    position: absolute;
    left: 0%;
    top: 100px;
    width: 100%;
    height: 600px;
    flex-direction: column;
    margin: auto;
    gap: 40px;
}

.fields1 {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    padding: 20px 56px;
    border-radius: 12px;
    background-color: #fff;
    overflow: hidden;
}

.intro {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    gap: 24px;
}

.creatingYourAccount {
    color: #262626;
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
    font-family: DM Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    height: min-content;
    align-self: stretch;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.root {
    color: #575757;
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    height: min-content;
    align-self: stretch;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.copyright {
    height: 60px;
    width: 100%;
    align-self: stretch;
  }
  .copyright2023PaymentsenseLimte {
    color: #575757;
    font-size: 14px;
    line-height: 22px;
    font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    height: min-content;
    flex: 1;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

.getStarted2 {
    color: #262626;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    font-family: DM Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
}

.clapyResets,
.clapyResets * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  flex-shrink: 0;
  text-decoration: none;
  min-width: 0;
  overflow-wrap: anywhere;
}

.clapyResets,
.clapyResets :not(span, a, ul, ol, li, p) {
  display: flex;
}

.clapyResets ul,
.clapyResets ol {
  text-indent: 0;
  padding-inline-start: 1.5em;
}

.clapyResets p:empty::before {
  content: '';
  display: inline-block;
}

.clapyResets img {
  object-fit: contain;
}

.clapyResets svg {
  overflow: visible;
}
